*{
  margin: 0;
  padding: 0;
  
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: right;
}
li a {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li a:hover {
  background-color: #111;
}

#mf_container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  padding: 50px 100px;
  background-color: #F0EB8D;
  min-height: calc(100vh - 150px);
}
.movie_folder, .movie_folder1 {
  border-radius: 10px;
  background-color: black;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #F0EB8D;  
  cursor: pointer;
}

.movie_folder:first-of-type{
display:none !important
}
.movie_cover{
width:auto;
height:100%
}
